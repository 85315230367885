import { Bullets } from "ui-components";
import UserImage from "../../../../utils/Images/UserImage";
import { manageDuplicateSkills } from "../../../../utils/Common/Constant";
import { useTranslation } from "react-i18next";
interface QuestionResponsePopupHeader {
  setShowQuestion: any;
  assessmentApplicantDetails: any;
  assessmentSkill: any;
  assessmentDetails: any;
  assessmentDateTime: string;
  setIsMaximize: any;
  isMaximize: boolean;
}
const QuestionResponsePopupHeader: React.FC<QuestionResponsePopupHeader> = ({
  assessmentApplicantDetails,
  assessmentSkill,
  assessmentDetails,
  assessmentDateTime,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="contentUserIcon">
        <div className="userIcon">
          <div className="userImageBorder">
            {assessmentApplicantDetails?.applicantImage === null ? (
              <UserImage />
            ) : (
              <img src={assessmentApplicantDetails?.applicantImage} alt="" />
            )}
          </div>
        </div>
        <div className="content">
          <span className="nameSurname">
            {assessmentApplicantDetails?.displayName}
          </span>
          <p className="prograamingName">{assessmentApplicantDetails?.title}</p>
          {assessmentSkill?.length > 0 && (
            <div className="bullets">
              {(manageDuplicateSkills(assessmentSkill) ?? []).map(
                (skillList: any) => (
                  <Bullets
                    key={Math.random()}
                    skillName={skillList?.skillName ?? ""}
                  />
                ),
              )}
            </div>
          )}
          <div className="dateTime">
            <span>{assessmentDateTime}</span>
            <span>|</span>
            <span>
              {t("CREATE_ASSESSMENT.DURATION")} :-{" "}
              {assessmentDetails?.totalTime} {t("GENERAL.MINS")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionResponsePopupHeader;
