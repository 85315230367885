import ExperienceLeftSide from "./ExperienceLeftSide/ExperienceLeftSide";
import "./index.scss";
import ExperienceRightSide from "./ExperienceRightSide/ExperienceRightSide";
import { AssessmentJsonContext } from "../../../../context/JsonContext/JsonContext";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Head from "../AssessmentScope/Head/Head";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getRoleCentricRender } from "../../../../service/Scope/Scope";
import { checkKeysInSessionStorage } from "../../../../utils/Common/checkKeysInSessionStorage";
import SetDraftJsonInSession from "../../AssessmentDraftJson/SetDraftJsonInSession";
// import { AssessmentDraftContext } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import {
  createAssessmentDraftJson,
  getCreateAssessmentDraftJson,
} from "../../../../service/DraftJson/DraftJson";
import { assessmentDraftJson } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import { Form, Formik } from "formik";
import {
  calculateMinusAmount,
  getAssessmentPath,
  initialSettingData,
  addDisableCss,
  determinePageType,
  checkUniqueIdMatch,
  drafteditedformData,
  editedformData,
} from "../../../../utils/Common/Constant";
import { showToast } from "ui-components";
import { useTranslation } from "react-i18next";
import { getSessionStorageItem } from "../../../../utils/SessionStorageMethod/getSessionStorageItem";
import * as Yup from "yup";
import AdvanceSetting from "./AdvanceSetting/AdvanceSetting";
import { systemPreferenceContext } from "../../../../context/SystemPreference/SystemPreference";
import { getAssessmentDetailsByAssessementId } from "../../../../service/SubmitAssessment/SubmitAssessment";

const AssessmentReview: React.FunctionComponent = () => {
  const textEditorLimit = 1001;
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  const [countTextInDecription, setCountTextInDecription] = useState<number>(
    getSessionStorageItem("countTextInDecription") || textEditorLimit,
  );
  const [countManageDesFlag, setCountManageDesFlag] = useState(false);

  const [countTextInInstruction, setCountTextInInstruction] = useState<number>(
    getSessionStorageItem("countTextInInstruction") || textEditorLimit,
  );
  const [countManageInsFlag, setCountManageInsFlag] = useState(false);
  const [advanceSettingData, setAdvanceSettingData] = useState(() => {
    const savedData = sessionStorage.getItem("advanceSettingData");
    return savedData
      ? JSON.parse(savedData)
      : {
          location: "",
          jobType: "",
          engagementType: "",
          disable: true,
        };
  });
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const editedParams = new URLSearchParams(location.search).get("edited");
  // Get current language
  const currentLanguage = i18n.language;
  const { tempUniqueId } = useParams();
  const renderRouteName = "setting";
  const userId = localStorage.getItem("companyId") ?? "";
  const { pathname } = useLocation();
  const activeStep = determinePageType(pathname);
  const navigate = useNavigate();
  // const formikContext = useFormikContext();
  const { preferenceData } = useContext(systemPreferenceContext);
  // const { setFieldValue } = formikContext || {};
  const [experienceMinValue, setExperienceMinValue] = useState<number>(
    preferenceData?.createAssessment?.minimumExperience !== undefined
      ? getSessionStorageItem("minimumExperience") === null
        ? Number(preferenceData.createAssessment.minimumExperience)
        : getSessionStorageItem("minimumExperience")
      : getSessionStorageItem("minimumExperience"),
  );
  const [experienceMaxValue, setExperienceMaxValue] = useState<number>(
    preferenceData?.createAssessment?.maximumExperience !== undefined
      ? getSessionStorageItem("maximumExperience") ||
          Number(preferenceData.createAssessment.maximumExperience)
      : getSessionStorageItem("maximumExperience"),
  );
  const [noOfPositions, setNoOfPositions] = useState<number>(
    preferenceData?.createAssessment?.noOfPositions !== undefined
      ? getSessionStorageItem("noOfPositions") ||
          Number(preferenceData.createAssessment.noOfPositions)
      : getSessionStorageItem("noOfPositions"),
  );
  const [cutOff, setCutOff] = useState<number>(
    preferenceData?.createAssessment?.cutOff !== undefined
      ? getSessionStorageItem("cutOff") ||
          Number(preferenceData.createAssessment.cutOff)
      : getSessionStorageItem("cutOff"),
  );
  const [maximumResponse, setMaximumResponse] = useState<number>(
    preferenceData?.createAssessment?.maximumResponse !== undefined
      ? getSessionStorageItem("maximumResponse") ||
          Number(preferenceData.createAssessment.maximumResponse)
      : getSessionStorageItem("maximumResponse"),
  );
  const [editorDescription, setEditorDescription] = useState<any>(
    getSessionStorageItem("description") || "",
  );
  const [editorInstruction, setEditorInstruction] = useState<any>(
    getSessionStorageItem("instruction") || "",
  );
  const { state, dispatch } = useContext(AssessmentJsonContext) ?? {
    state: {
      renderJson: {},
      count: 0,
    },
    dispatch: () => {},
  };

  const settingValidationSchema = Yup.object({
    positionsCount: Yup.number()
      .moreThan(0, t("ERROR.POSITION_COUNT_GREATER_ZERO"))
      .required(t("ERROR.POSITION_COUNT_REQUIRED")),
  });

  useEffect(() => {
    (async () => {
      const getJsonData = await getRoleCentricRender(renderRouteName);
      dispatch({
        type: "UPDATE_CENTRIC_RENDER_JSON",
        payload: getJsonData,
      });
    })();
    const isKeyPresent = checkKeysInSessionStorage(
      [
        "Type",
        "titleHeadContent",
        "createQuestionData",
        "ownPreview",
        "existingPreview",
      ],
      "any",
    );
    if (
      (!isKeyPresent && !editedParams) ||
      checkUniqueIdMatch(tempUniqueId, tempUniqueIdBySession)
    ) {
      SetDraftJsonInSession(userId, tempUniqueId, pathname);
    }
  }, []);
  // render when edited assessment
  useEffect(() => {
    (async () => {
      if (editedParams) {
        const draftRes = await getCreateAssessmentDraftJson(
          userId,
          tempUniqueId,
        );
        if (
          Object.keys(draftRes?.data?.data).length > 0 &&
          draftRes?.data?.data?.createAssessmentJson?.setting
            ?.maximumExperience &&
          draftRes?.data?.data?.createAssessmentJson?.setting?.minimumExperience
        ) {
          setExperienceMinValue(
            draftRes?.data?.data?.createAssessmentJson?.setting
              ?.minimumExperience,
          );
          setExperienceMaxValue(
            draftRes?.data?.data?.createAssessmentJson?.setting
              ?.maximumExperience,
          );

          setNoOfPositions(
            draftRes?.data?.data?.createAssessmentJson?.setting?.noOfPositions,
          );
          setCutOff(
            draftRes?.data?.data?.createAssessmentJson?.setting?.cutOff,
          );
          setMaximumResponse(
            draftRes?.data?.data?.createAssessmentJson?.setting
              ?.maximumResponse,
          );
          setEditorDescription(
            draftRes?.data?.data?.createAssessmentJson?.setting?.description,
          );
          setEditorInstruction(
            draftRes?.data?.data?.createAssessmentJson?.setting?.instructions,
          );
          setAdvanceSettingData(
            draftRes?.data?.data?.createAssessmentJson?.setting
              ?.advanceSettingData,
          );
          drafteditedformData(draftRes?.data?.data, "setting");
        } else {
          const editedData = await getAssessmentDetailsByAssessementId(
            tempUniqueId ?? "",
          );
          setExperienceMinValue(editedData?.data?.minimumExperience);
          setExperienceMaxValue(editedData?.data?.maximumExperience);
          setNoOfPositions(editedData?.data?.totalPositions);
          setCutOff(editedData?.data?.cutOff);
          setMaximumResponse(editedData?.data?.maxApplications);
          setEditorDescription(editedData?.data?.description);
          setEditorInstruction(editedData?.data?.instructions);
          const advanceData = {
            location: editedData?.data?.jobLocation,
            engagementType: editedData?.data?.engagementType,
            jobType: editedData?.data?.jobType,
            disable: true,
          };
          setAdvanceSettingData(advanceData);
          editedformData(editedData, "setting");
        }
      }
    })();
  }, []);
  // Run this effect whenever the session storage is updated
  useEffect(() => {
    setExperienceMinValue(getSessionStorageItem("minimumExperience"));
    setExperienceMaxValue(getSessionStorageItem("maximumExperience"));
    setNoOfPositions(getSessionStorageItem("noOfPositions"));
    setCutOff(getSessionStorageItem("cutOff"));
    setMaximumResponse(getSessionStorageItem("maximumResponse"));
    setAdvanceSettingData(getSessionStorageItem("advanceSettingData"));
  }, [
    getSessionStorageItem("noOfPositions"),
    getSessionStorageItem("minimumExperience"),
    getSessionStorageItem("cutOff"),
    getSessionStorageItem("maximumExperience"),
    getSessionStorageItem("maximumResponse"),
  ]);
  // to move to next page
  // const handleNext = async () => {
  const handleNext = async (count: number) => {
    if (
      calculateMinusAmount(countTextInDecription, textEditorLimit) < 0 ||
      calculateMinusAmount(countTextInInstruction, textEditorLimit) < 0
    ) {
      if (calculateMinusAmount(countTextInDecription, textEditorLimit) < 0) {
        showToast(t("CREATE_ASSESSMENT.DESCRIPTION_LIMIT_EXEED"), "error");
      }
      if (calculateMinusAmount(countTextInInstruction, textEditorLimit) < 0) {
        showToast(t("CREATE_ASSESSMENT.INSTRUCTION_LIMIT_EXEED"), "error");
      }
      return;
    }
    let nextStep;
    if (count != null && count != undefined && typeof count === "number") {
      nextStep = activeStep < 5 ? count : activeStep;
    } else {
      nextStep = activeStep < 5 ? activeStep + 1 : activeStep;
    }
    if (activeStep < count) {
      await createAssessmentDraftJson(assessmentDraftJson(tempUniqueId));
    }
    setSessionStorageItem("currentStep", nextStep);
    navigate(
      getAssessmentPath(nextStep, tempUniqueId, currentLanguage, editedParams),
    );
  };
  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      setSessionStorageItem("currentStep", count);
      navigate(
        getAssessmentPath(count, tempUniqueId, currentLanguage, editedParams),
      );
    } else {
      const prevStep = activeStep > 1 ? activeStep - 1 : activeStep;
      setSessionStorageItem("currentStep", prevStep);
      navigate(
        getAssessmentPath(
          prevStep,
          tempUniqueId,
          currentLanguage,
          editedParams,
        ),
      );
    }
  };
  return (
    <>
      <Formik
        initialValues={initialSettingData}
        validationSchema={settingValidationSchema}
        onSubmit={() => {
          // onSubmit(values);
          // handleNext();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="rangeTextSection">
                <div className="row">
                  <div
                    className={`${addDisableCss(
                      state?.renderJson?.setting?.range?.functionBehavior,
                    )} col-md-6`}
                  >
                    <ExperienceLeftSide
                      errors={errors}
                      touched={touched}
                      experienceMinValue={experienceMinValue}
                      setExperienceMinValue={setExperienceMinValue}
                      experienceMaxValue={experienceMaxValue}
                      setExperienceMaxValue={setExperienceMaxValue}
                      noOfPositions={noOfPositions}
                      setNoOfPositions={setNoOfPositions}
                      cutOff={cutOff}
                      setCutOff={setCutOff}
                      maximumResponse={maximumResponse}
                      setMaximumResponse={setMaximumResponse}
                    />
                    <div className={"advanceSetting "}>
                      <AdvanceSetting
                        setAdvanceSettingData={setAdvanceSettingData}
                        advanceSettingData={advanceSettingData}
                      />
                    </div>
                  </div>
                  <div
                    className={`${addDisableCss(
                      state?.renderJson?.setting?.textEditor?.functionBehavior,
                    )} col-md-6`}
                  >
                    <ExperienceRightSide
                      textEditorLimit={textEditorLimit}
                      countTextInDecription={countTextInDecription}
                      setCountTextInDecription={setCountTextInDecription}
                      countManageDesFlag={countManageDesFlag}
                      setCountManageDesFlag={setCountManageDesFlag}
                      countTextInInstruction={countTextInInstruction}
                      setCountTextInInstruction={setCountTextInInstruction}
                      countManageInsFlag={countManageInsFlag}
                      setCountManageInsFlag={setCountManageInsFlag}
                      editorDescription={editorDescription}
                      setEditorDescription={setEditorDescription}
                      editorInstruction={editorInstruction}
                      setEditorInstruction={setEditorInstruction}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentReview;
