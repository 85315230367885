import { APIClient } from "smartassess-api-lib";
const apiClient = new APIClient();
const companyToken: any = localStorage.getItem("companyToken");
// apiClient.initializeApiClient(`${process.env.REACT_APP_API}`);
apiClient.initializeApiClient(
  `${process.env.REACT_APP_API}`,
  companyToken,
  false,
);
export default apiClient;
