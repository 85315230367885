import { useTranslation } from "react-i18next";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import "./QuestionResponsePopup.scss";
import QuestionResponsePopupHeader from "./QuestionResponsePopupHeader";
import { showToast } from "ui-components";
import { useEffect, useRef, useState } from "react";
import UserImage from "../../../../utils/Images/UserImage";
import QuestionRapper from "../QuestionType/QuestionRapper";
import AddCommentAndReport from "./AddCommentAndReport";
import {
  commentAgoTime,
  enterFullScreen,
  exitFullScreen,
  scrollToTop,
} from "../../../../utils/Common/Constant";
import {
  addCommentOverQuestion,
  assessmentApplicantResponseManualReview,
  assessmentQuestionReport,
  getAssessmentEvaluationComment,
  updateAssessmentApplicantQuestionFinalScore,
} from "../../../../service/CreatedAssessmentListAndReview/CreatedAssessmentListAndReview";
import ManualScore from "./ManualScore";
// import useDebounceFn from "../../../../utils/Hooks/UseDebounceFn";
import PreviousIcon from "../../../../utils/Images/PreviousIcon";
import NextIcon from "../../../../utils/Images/NextIcon";
import AddReport from "./AddReport";
import { getReportedQuestion } from "../../../../service/Scope/Scope";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../i18nConfig";
import CircleCrossIcon from "../../../../utils/Images/CircleCrossIcon";
interface QuestionResponsePopup {
  setShowQuestion: any;
  assessmentApplicantDetails: any;
  assessmentSkill: any;
  questionRapperData: any;
  assessmentDetails: any;
  assessmentDateTime: string;
  questionData: any;
  setIsScoreUpdated: any;
  evaluationTypesObj: any;
}
const QuestionResponsePopup: React.FC<QuestionResponsePopup> = ({
  setShowQuestion,
  assessmentApplicantDetails,
  assessmentSkill,
  questionRapperData,
  assessmentDetails,
  assessmentDateTime,
  questionData,
  setIsScoreUpdated,
  evaluationTypesObj,
}) => {
  const { t } = useTranslation();
  const [rangeValue, setRangeValue] = useState(
    questionRapperData?.questionData?.evaluationResult?.filter(
      (el: any) => el.evaluationType === "manual",
    )?.[0]?.score ?? 0,
  );
  const [addComment, setAddComment] = useState("");
  const [addReport, setAddReport] = useState("");
  const [isReport, setIsReport] = useState(false);
  const [evaluationComment, setEvaluationComment] = useState([]);
  const [isMaximize, setIsMaximize] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(
    questionRapperData?.questionData,
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    questionRapperData?.index,
  );
  const [isPaused, setIsPaused] = useState(false);
  const [questionNo, setQuestionNo] = useState(questionRapperData?.questionNo);
  const [isAiEvaluation, setIsAiEvaluation] = useState({
    evaluationType: "manual",
    isEvaluation: false,
  });
  const [reportedData, setReportedData] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [refreshVideo, setRefreshVideo] = useState(0);
  const applicantId = new URLSearchParams(location.search).get("aplid");
  const nextBtnRef = useRef<any>(null);
  const previousBtnRef = useRef<any>(null);
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  useEffect(() => {
    setCurrentQuestion(questionRapperData?.questionData);
    setCurrentQuestionIndex(questionRapperData?.index);
    setQuestionNo(questionRapperData?.questionNo);
  }, [questionRapperData?.index]);
  useEffect(() => {
    handleGetEvaluationComment();
    handleGetReportedData();
  }, [currentQuestionIndex]);
  useEffect(() => {
    if (questionData[currentQuestionIndex]?.scoreType) {
      if (questionData[currentQuestionIndex]?.scoreType === "smartAi") {
        setIsAiEvaluation({
          evaluationType: "smartAi",
          isEvaluation: true,
        });
      } else if (questionData[currentQuestionIndex]?.scoreType === "manual") {
        setIsAiEvaluation({
          evaluationType: "manual",
          isEvaluation: false,
        });
      }
    } else {
      setIsAiEvaluation({
        evaluationType: "manual",
        isEvaluation: false,
      });
    }
  }, [currentQuestion?.assessmentApplicantQuestionId]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        const newIndex = findNextNonSkippedQuestion(currentQuestionIndex);
        if (newIndex < questionData.length) {
          nextBtnRef.current?.classList.add("btnActive");
          handleNext();
        }
      }
      if (event.key === "ArrowLeft") {
        const newIndex = findPreviousNonSkippedQuestion(currentQuestionIndex);
        if (newIndex >= 0) {
          previousBtnRef.current?.classList.add("btnActive");
          handlePrevious();
        }
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        nextBtnRef.current?.classList.remove("btnActive");
      }
      if (event.key === "ArrowLeft") {
        previousBtnRef.current?.classList.remove("btnActive");
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [currentQuestionIndex, questionData]);
  useEffect(() => {
    if (currentQuestion?.isReported) {
      // Perform any additional actions if needed when isReported becomes true
    }
  }, [currentQuestion?.isReported]);
  const handleRangeValue = (value: number, setFn: any) => {
    if (isFirstTime) {
      setFn(value);
      setIsFirstTime(false);
    } else {
      setFn(value);
    }
  };
  const handleManualReview = async () => {
    const smartAiEvaluation = currentQuestion?.evaluationResult?.filter(
      (el: any) =>
        el.evaluationType === "smartAi_all" ||
        el.evaluationType === "smart_correctness_visual" ||
        el.evaluationType === "smart_correctness_audio" ||
        el.evaluationType === "smart_correctness",
    );
    if (isAiEvaluation?.evaluationType === "smartAi") {
      if (smartAiEvaluation && smartAiEvaluation.length > 0) {
        const { score } = smartAiEvaluation[0];
        const smartAiData = {
          assessmentApplicantQuestionId:
            currentQuestion?.assessmentApplicantQuestionId,
          score: score ?? 0,
          scoreType: isAiEvaluation?.evaluationType,
        };
        const updateRes =
          await updateAssessmentApplicantQuestionFinalScore(smartAiData);
        if (updateRes?.data?.status === 200) {
          showToast(updateRes?.data?.customMessage, "success");
        }
        setIsScoreUpdated(Math.random() * 10); //use to update the background screen score
      } else {
        showToast("AI analysis not available.", "error");
      }
    } else {
      const manualReviewData = {
        assessmentApplicantQuestionId:
          currentQuestion?.assessmentApplicantQuestionId,
        score: rangeValue,
        evaluatedBy: localStorage.getItem("companyUserId"),
      };
      const updateRes =
        await assessmentApplicantResponseManualReview(manualReviewData);
      if (updateRes?.data?.status === 200) {
        showToast(updateRes?.data?.customMessage, "success");
      }
      setIsScoreUpdated(Math.random() * 10); // use to update the background screen score
      const manualScoreData = {
        assessmentApplicantQuestionId:
          currentQuestion?.assessmentApplicantQuestionId,
        score: rangeValue,
        scoreType: isAiEvaluation?.evaluationType,
      };
      const finalScoreUpdate =
        await updateAssessmentApplicantQuestionFinalScore(manualScoreData);
      if (finalScoreUpdate?.data?.status === 200) {
        setIsScoreUpdated(Math.random() * 10); //use to update the background screen score
      }
    }
  };
  // function for add comment
  const handleSubmitComment = async () => {
    const commentData = {
      assessmentApplicantQuestionId:
        currentQuestion?.assessmentApplicantQuestionId,
      questionComment: addComment,
      companyUserId: localStorage.getItem("companyUserId"),
    };
    const commentRes = await addCommentOverQuestion(commentData);
    if (commentRes?.status === 200) {
      setAddComment("");
      showToast(commentRes?.data?.customMessage, "success");
      handleGetEvaluationComment();
    }
  };
  // function for add report
  const handleSubmitReport = async () => {
    const reportData = {
      assessmentId: assessmentDetails?.assessmentId,
      questionId: currentQuestion?.questionId,
      assessmentApplicantQuestionId:
        currentQuestion?.assessmentApplicantQuestionId,
      question:
        currentQuestion?.question?.question || currentQuestion?.question,
      comment: addReport,
      reportedById: localStorage.getItem("companyUserId"),
      questionType: currentQuestion?.type,
    };
    const reportRes = await assessmentQuestionReport(reportData);
    if (reportRes?.status === 200) {
      showToast(reportRes?.data?.customMessage, "success");
      setAddReport("");
      setIsReport(!isReport);
      handleGetReportedData();
      setKey((prevKey) => prevKey + 1);
      setIsScoreUpdated(Math.random() * 10);
    }
  };
  // function to get Assessment Evaluation Comment
  const handleGetEvaluationComment = async () => {
    const evaluationCommentRes = await getAssessmentEvaluationComment(
      currentQuestion?.assessmentApplicantQuestionId || applicantId,
    );
    if (evaluationCommentRes?.data?.status === 200) {
      setEvaluationComment(evaluationCommentRes?.data?.data?.data);
    }
  };
  //functions to switch non-skipped question in popup
  const findPreviousNonSkippedQuestion = (index: number) => {
    let newIndex = index - 1;
    while (newIndex >= 0 && questionData[newIndex]?.status === "skipped") {
      newIndex--;
    }
    return newIndex;
  };
  const findNextNonSkippedQuestion = (index: number) => {
    let newIndex = index + 1;
    while (
      newIndex < questionData.length &&
      questionData[newIndex]?.status === "skipped"
    ) {
      newIndex++;
    }
    return newIndex;
  };
  const handlePrevious = () => {
    scrollToTop();
    setRefreshVideo((prevKey) => prevKey + 1);
    const newIndex = findPreviousNonSkippedQuestion(currentQuestionIndex);
    if (newIndex >= 0) {
      setCurrentQuestionIndex(newIndex);
      setCurrentQuestion(questionData[newIndex]);
      setQuestionNo(questionData[newIndex]?.questionSequence);
      setRangeValue(
        questionData[newIndex]?.evaluationResult?.filter(
          (el: any) => el.evaluationType === "manual",
        )?.[0]?.score ?? 0,
      );
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("ind", JSON.stringify(newIndex));
      history.replaceState({}, "", url.toString());
      setIsPaused(true);
    }
  };
  const handleNext = () => {
    scrollToTop();
    setRefreshVideo((prevKey) => prevKey + 1);
    const newIndex = findNextNonSkippedQuestion(currentQuestionIndex);
    if (newIndex < questionData.length) {
      setCurrentQuestionIndex(newIndex);
      setCurrentQuestion(questionData[newIndex]);
      setRangeValue(
        questionData[newIndex]?.evaluationResult?.filter(
          (el: any) => el.evaluationType === "manual",
        )?.[0]?.score ?? 0,
      );
      setQuestionNo(questionData[newIndex]?.questionSequence);
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("ind", JSON.stringify(newIndex));
      history.replaceState({}, "", url.toString());
      setIsPaused(true);
    }
  };
  const handleGetReportedData = async () => {
    const getReportedData = await getReportedQuestion(
      assessmentDetails?.assessmentId,
      currentQuestion?.questionId,
    );
    if (getReportedData?.status === 200) {
      setReportedData(getReportedData?.data);
    } else {
      if (getReportedData?.status === 409) {
        navigate(`/${currentLanguage}/404-not-found`);
      }
    }
  };
  const isPreviousDisabled =
    findPreviousNonSkippedQuestion(currentQuestionIndex) < 0;
  const isNextDisabled =
    findNextNonSkippedQuestion(currentQuestionIndex) >= questionData.length;
  const handleClose = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.delete("ind");
    url.searchParams.set("openPopup", "false");
    history.replaceState({}, "", url.toString());
    const isPopUpOpen: any = new URLSearchParams(location.search).get(
      "openPopup",
    );
    setShowQuestion(JSON.parse(isPopUpOpen));
    url.searchParams.delete("ind");
    exitFullScreen();
  };
  // Function to detect full-screen mode
  const checkFullScreen = () => {
    const fullScreenElement =
      document.fullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).msFullscreenElement;
    setIsMaximize(!!fullScreenElement);
  };
  // Handle key press events
  const handleKeyDown = (event: KeyboardEvent) => {
    // Detect the "F" key with Command + Control on macOS (this is just an example)
    if (event.key === "f" && (event.metaKey || event.ctrlKey)) {
      if (isMaximize) {
        exitFullScreen();
        setIsMaximize(false);
      } else {
        enterFullScreen();
        setIsMaximize(true);
      }
    }
  };
  useEffect(() => {
    // Initial check
    checkFullScreen();
    // Event listeners for detecting changes
    document.addEventListener("fullscreenchange", checkFullScreen);
    document.addEventListener("webkitfullscreenchange", checkFullScreen);
    document.addEventListener("mozfullscreenchange", checkFullScreen);
    document.addEventListener("MSFullscreenChange", checkFullScreen);
    window.addEventListener("resize", checkFullScreen);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("fullscreenchange", checkFullScreen);
      document.removeEventListener("webkitfullscreenchange", checkFullScreen);
      document.removeEventListener("mozfullscreenchange", checkFullScreen);
      document.removeEventListener("MSFullscreenChange", checkFullScreen);
      window.removeEventListener("resize", checkFullScreen);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMaximize]);
  return (
    <CustomModal
      instructionHeadTitle={t("GENERAL.CAPTURE_IMAGE")}
      show={true}
      handleClose={() => setShowQuestion(false)}
      isCloseEnable={true}
      size="xl"
      // className={isMaximize ? "maximizerCls" : ""}
      isCrossBtn={false}
      isHeaderVisible={false}
      isFooterVisible={false}
      scrollable={true}
      key={key}
    >
      {
        <>
          <div className="cross-fullscreen-sec">
            <div
              onClick={isMaximize ? exitFullScreen : enterFullScreen}
              className={"minMaxText"}
            >
              <h6>
                {!isMaximize
                  ? t("GENERAL.FULL_SCREEN")
                  : t("GENERAL.EXIT_FULL_SCREEN")}
              </h6>
            </div>
            <div className="exitPopup">
              <div
                onClick={() => handleClose()}
                className="iconStyle closeModelIcon"
              >
                {/* <h6>{t("GENERAL.EXIT")}</h6> */}
                <CircleCrossIcon />
              </div>
            </div>
          </div>
          <div className="questionResponsePopup">
            <div className="questionResponsePopupHeader">
              <QuestionResponsePopupHeader
                setShowQuestion={setShowQuestion}
                assessmentApplicantDetails={assessmentApplicantDetails}
                assessmentSkill={assessmentSkill}
                assessmentDetails={assessmentDetails}
                assessmentDateTime={assessmentDateTime}
                setIsMaximize={setIsMaximize}
                isMaximize={isMaximize}
              />
            </div>
            {
              <div className={`report-div ${reportedData && "mt-5"}`}>
                <div className="reportBtn margintopReportBtn">
                  {!reportedData ? (
                    !assessmentApplicantDetails?.isNotified && (
                      <p
                        onClick={() => setIsReport(!isReport)}
                        className="questionResponseReportIt"
                      >
                        {t("SUBMIT_ASSESSMENT_REPORT_IT")}
                      </p>
                    )
                  ) : (
                    <p className="reportedText">{t("GENERAL.REPORTED")}</p>
                  )}
                </div>

                {!reportedData ? (
                  isReport && (
                    <>
                      <AddReport
                        value={addReport}
                        placeHolder={t("RESPONSE_LIST.REPORT_YOUR_QUERY")}
                        btnName={t("GENERAL.REPORT")}
                        setFn={setAddReport}
                        handleSubmit={handleSubmitReport}
                      />
                      <hr />
                    </>
                  )
                ) : (
                  <div className="reportedComment">
                    <span>{reportedData?.comment ?? "-"}</span>
                    <hr />
                  </div>
                )}
              </div>
            }

            <div className="bulletSec">
              <div className="questionSkillName">
                {currentQuestion?.isCustomQuestion
                  ? t("GENERAL.CUSTOM_QUESTION")
                  : `${currentQuestion?.skillName} - ${currentQuestion?.difficultyLevel} `}
              </div>
            </div>

            <div className="changeQuestions">
              {!isPreviousDisabled && (
                <button
                  ref={previousBtnRef}
                  disabled={isPreviousDisabled}
                  className={
                    isPreviousDisabled
                      ? "prevBtn disableBtnCss"
                      : "prevBtn btnSlick"
                  }
                  onClick={() => handlePrevious()}
                >
                  <PreviousIcon />
                </button>
              )}
              <QuestionRapper
                key={refreshVideo}
                questionRapperData={{
                  index: currentQuestionIndex + 1,
                  questionData: currentQuestion,
                  questionNo: questionNo,
                }}
                handleAIClick={() => {
                  setIsAiEvaluation({
                    evaluationType: "smartAi",
                    isEvaluation: true,
                  });
                }}
                isAiEvaluation={isAiEvaluation}
                isPaused={isPaused}
                isMaximize={isMaximize}
                evaluationTypesObj={evaluationTypesObj}
                handleManualReview={handleManualReview}
                isNotified={
                  assessmentApplicantDetails?.isNotified ||
                  currentQuestion?.isReported
                }
              />
              <div className="manualScoreSection">
                {questionRapperData?.questionData?.status === "skipped" ? (
                  <div className="skipQuestionStatus">
                    <h1 className="skipStatus">{t("RESPONSE_LIST.SKIPPED")}</h1>
                    <div className="skipScore mt-1">
                      <p className="skipSubScore">{t("GENERAL.SCORE")} :</p>
                      <p className="skipPercentageScore">
                        {questionRapperData?.questionData?.evaluationResult
                          ?.score
                          ? questionRapperData?.questionData?.evaluationResult
                              ?.score
                          : 0}
                        %
                      </p>
                    </div>
                  </div>
                ) : currentQuestion?.type !== "Single" &&
                  currentQuestion?.type !== "Multiple" ? (
                  <>
                    <ManualScore
                      handleRangeValue={handleRangeValue}
                      handleManualReview={handleManualReview}
                      isVideoType={
                        currentQuestion?.type === "Video" ? true : false
                      }
                      rangeValue={rangeValue}
                      setFn={setRangeValue}
                      handleManualClick={() =>
                        setIsAiEvaluation({
                          evaluationType: "manual",
                          isEvaluation: false,
                        })
                      }
                      isManualEvaluation={isAiEvaluation}
                      isNotified={
                        assessmentApplicantDetails?.isNotified ||
                        currentQuestion?.isReported
                      }
                    />
                  </>
                ) : (
                  <>
                    <div className="subScore mt-2">
                      <h1
                        className={`${currentQuestion?.totalScore > 0 ? "answerCorrectTag" : "answerWrongTag"}`}
                      >
                        {currentQuestion?.totalScore > 0
                          ? t("RESPONSE_LIST.CORRECT_ANSWER")
                          : t("RESPONSE_LIST.WRONG_ANSWER")}
                      </h1>
                      <div className="d-flex gap-2 align-items-center">
                        <p className="manualSubScore">{t("GENERAL.SCORE")} :</p>
                        <p className="manualPercentageScore">
                          {currentQuestion?.evaluationResult[0]?.score
                            ? currentQuestion?.evaluationResult[0]?.score
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex sortByDiv">
                  <h4>
                    {evaluationComment?.length} {t("RESPONSE_LIST.COMMENTS")}
                  </h4>
                </div>
                {!assessmentApplicantDetails?.isNotified && (
                  <div className="commentSection">
                    <AddCommentAndReport
                      value={addComment}
                      placeHolder={t("RESPONSE_LIST.ADD_A_COMMENT")}
                      btnName={t("GENERAL.COMMENT")}
                      setFn={setAddComment}
                      handleSubmit={handleSubmitComment}
                    />
                  </div>
                )}
                <div className="manualProfile">
                  {(evaluationComment ?? [])?.map((item: any, index: any) => {
                    return (
                      <>
                        <div className="d-flex manualProfileSec">
                          <div className="manualProfileIcon">
                            <UserImage />
                          </div>
                          <div className="manualProfileHeading">
                            <h4>{item?.companyUser?.displayName}</h4>
                            <p>{commentAgoTime(item?.createdAt)}</p>
                          </div>
                        </div>
                        <p className="manualProfilePara">
                          {item?.questionComment}
                        </p>
                        {index < evaluationComment.length - 1 && <hr />}
                      </>
                    );
                  })}
                  {/* future use */}
                  {/* <div className="text-center">
                  <AssessmentButton disabled={true}>
                    {t("GENERAL.LOAD_MORE")}
                  </AssessmentButton>
                </div> */}
                </div>
              </div>
              {!isNextDisabled && (
                <button
                  disabled={isNextDisabled}
                  onClick={() => handleNext()}
                  className={
                    isNextDisabled
                      ? "nextBtn disableBtnCss"
                      : "nextBtn btnSlick"
                  }
                  ref={nextBtnRef}
                >
                  <NextIcon />
                </button>
              )}
            </div>
          </div>
        </>
      }
    </CustomModal>
  );
};

export default QuestionResponsePopup;
