import React, { useEffect, useState } from "react";
import JobTypeSelector from "./JobTypeSelector";
import EngagementType from "./EngagementType";
import Location from "./Location";
import "./AdvanceSetting.scss";
import { useTranslation } from "react-i18next";

interface AdvanceSetting {
  setAdvanceSettingData: any;
  advanceSettingData: any;
}

const AdvanceSetting: React.FC<AdvanceSetting> = ({
  setAdvanceSettingData,
  advanceSettingData,
}) => {
  const [isDisabled, setIsDisabled] = useState<any>(true);
  const { t } = useTranslation();
  const handleReset = () => {
    sessionStorage.setItem("additionalSettingDisable", "false");
    localStorage.removeItem("companyAddress");
    sessionStorage.removeItem("advanceSettingData");
    setAdvanceSettingData({
      location: "",
      jobType: "",
      engagementType: "",
      disable: false,
    });
    setIsDisabled(false);
  };
  useEffect(() => {
    if (sessionStorage.getItem("additionalSettingDisable") !== null) {
      const isLoggedInBoolean =
        sessionStorage.getItem("additionalSettingDisable") === "true";
      setIsDisabled(isLoggedInBoolean);
    } else {
      sessionStorage.setItem("additionalSettingDisable", "true");
    }
  }, []);
  return (
    <div className="advance-setting">
      <div className="advanceDiv">
        <span className="advance">{t("GENERAL.ADVANCE")}</span>
        <span onClick={handleReset} className="reset">
          {t("GENERAL.RESET")}
        </span>
      </div>
      <div className="mb-3 additionalSettingComponents">
        <Location
          setAdvanceSettingData={setAdvanceSettingData}
          advanceSettingData={{ ...advanceSettingData, disable: isDisabled }}
        />
        <JobTypeSelector
          setAdvanceSettingData={setAdvanceSettingData}
          advanceSettingData={{ ...advanceSettingData, disable: isDisabled }}
        />
        <EngagementType
          setAdvanceSettingData={setAdvanceSettingData}
          advanceSettingData={{ ...advanceSettingData, disable: isDisabled }}
        />
      </div>
    </div>
  );
};

export default AdvanceSetting;
