import { Pagination, TemplateSearch } from "ui-components";
import { useTranslation } from "react-i18next";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
const TransactionHistory = ({
  hasTransactionData,
  transaction,
  setTransactionSearch,
  transactionsSearch,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalCount,
}: any) => {
  const { t } = useTranslation();
  const columns: any = [
    { Header: t("ACCOUNT.TRANSACTIONS"), accessor: "Transactions" },
    { Header: t("GENERAL.DATE"), accessor: "date" },
    { Header: t("ACCOUNT.CREDITS"), accessor: "credits" },
    { Header: t("GENERAL.PAYMENT_MODE"), accessor: "type" },
    { Header: t("GENERAL.ACCOUNT_BALANCE"), accessor: "Account Balance" },
    { Header: t(" "), accessor: " " },
  ];

  return (
    <div className="account-container accountsRightDescription">
      <>
        {hasTransactionData && (
          <div className="search-bar">
            <TemplateSearch
              placeholder={t("GENERAL.SEARCH_EVENT")}
              iconName="searchList"
              value={transactionsSearch}
              onChange={(e) => {
                setTransactionSearch(e);
              }}
            />
          </div>
        )}
        <div className="accounts-table">
          <TransactionTable
            columns={columns}
            data={transaction}
            totalCount={totalCount}
          />
        </div>
        <div className="container row p-2">
          <div className="col-md-12">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
              ofLabel={t("GENERAL.OF")}
              resultLabel={t("GENERAL.RESULTS")}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default TransactionHistory;
