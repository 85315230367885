import { useEffect, useState } from "react";
import "./RechargeSlab.scss";
import { getRechargeSlab } from "../../../../service/Account/Account";
import RechargeSlabIcon from "../../../../utils/Images/RechargeSlabIcon";
import { useTranslation } from "react-i18next";
const RechargeSlab = ({ rechargeSlabId, setRechargeSlabId }: any) => {
  const [rechargeSlab, SetRechargeSlab] = useState<any>([]);
  const { t } = useTranslation();
  useEffect(() => {
    getSlab();
  }, []);
  const getSlab = async () => {
    const slabData = await getRechargeSlab(
      // "9cb9975d-bd7b-4f63-8200-16c023d27d47",
      "fe999709-f622-4ca0-859f-5a5bad77a708",
    );
    if (slabData?.length) {
      SetRechargeSlab(slabData);
    }
  };
  return (
    <div className="rechargeSlab">
      {rechargeSlab?.map((item: any, index: number) => {
        return (
          <div
            onClick={() => {
              if (index !== 0) {
                setRechargeSlabId(item);
              }
            }}
            key={index}
            className={`creditContent brCyan ${rechargeSlabId?.rechargeSlabId === item.rechargeSlabId ? "slabActive" : ""}`}
          >
            {index === 0 ? (
              <>
                <div>
                  <RechargeSlabIcon
                    color={index === 0 ? "#b3b3b3" : "#2EBEC6"}
                  />
                  <span>Free</span>
                </div>
                <div>
                  <h6>
                    {item?.credits} {t("PAYMENT.CREDITS")}
                  </h6>
                  <p>
                    {item?.extraCredits === 0 ? (
                      "Credit Used"
                    ) : (
                      <>
                        {t("PAYMENT.EXTRA")} {item?.extraCredits}
                      </>
                    )}
                  </p>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div>
                  <RechargeSlabIcon
                    color={index === 0 ? "#b3b3b3" : "#2EBEC6"}
                  />
                  <span className="unBld">$</span>
                  <span>{item?.amount}</span>
                </div>
                <div>
                  <h6>
                    {item?.credits} {t("PAYMENT.CREDITS")}
                  </h6>
                  <p>
                    {item?.extraCredits === 0 ? null : (
                      <>
                        {t("PAYMENT.EXTRA")} {item?.extraCredits}{" "}
                      </>
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RechargeSlab;
