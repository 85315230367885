import { ReactNode, useEffect, useState } from "react";
import "./CompanyOnboardWrapper.scss";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../utils/Images/GoogleIcon";
import LogoTagline from "../../utils/Images/LogoTagline";
import { useGoogleLogin } from "@react-oauth/google";
import { getEntireCustomUrl } from "smartassess-api-lib";
import { useOnlineStatus } from "ui-components";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18nConfig";
import handleApplicantLogin from "../../pages/Assessment/Auth/LoginApiCall";
interface props {
  children?: ReactNode;
  lowerSubmitText1?: string;
  lowerSubmitText2?: string;
  signInUpClick?: any;
  isGoogleSignBtn?: boolean;
  islogoVisible?: boolean;
  heading1?: string;
  heading2?: string;
  isOtpScreen?: boolean;
  emailId?: string;
}
const CompanyOnboardWrapper: React.FC<props> = ({
  children,
  lowerSubmitText1,
  lowerSubmitText2,
  signInUpClick,
  isGoogleSignBtn,
  islogoVisible,
  heading1,
  heading2,
  isOtpScreen,
  emailId,
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>([]);
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      setUser(credentialResponse);
    },
    onError: () => {
      console.error("Login Failed");
    },
  });
  useEffect(() => {
    if (user.access_token) {
      getEntireCustomUrl(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        },
      )
        .then((res: any) => {
          //call login function and pass parameters
          handleApplicantLogin(
            res?.email,
            "",
            "Google",
            res?.id,
            res?.verified_email,
            () => {},
            () => {},
            navigate,
            currentLanguage,
          );
        })
        .catch((err: any) => console.log(err));
    }
  }, [user]);
  return (
    <div className="container-fluid company-onboard-wrapper">
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      <div
        className={`onboardRow row no-gutters rowRev ${!isOnline && "mt-5"}`}
      >
        <div className="col-xl-6 justify-content-left ">
          <div className="left-partition forWebBg" />
          <div className="forMvBg" />
        </div>
        <div className="col-xl-6 right-partition d-flex flex-column justify-content-center">
          <div className="right-content">
            {!islogoVisible && (
              <div className="logo-header ">
                <div className="logo">
                  <LogoTagline />
                </div>
              </div>
            )}
            {heading1 || heading2 || isOtpScreen ? (
              <div className="heading-division">
                <h4 className="userHeadingApp">{heading1}</h4>
                <h1 className="userHeading">{heading2}</h1>
                {isOtpScreen && (
                  <div className="confirm-otp-text">
                    <span>
                      {t("GENERAL.ENTER_OTP")}{" "}
                      <span>
                        <b>{emailId}</b>
                      </span>
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            {isGoogleSignBtn && (
              <>
                <div
                  className="googleSignIn"
                  onClick={() => handleGoogleSignIn()}
                >
                  <GoogleIcon />
                  <span>{t("GENERAL.CONTINUE_WITH_GOOGLE")}</span>
                </div>
                <div className="divider my-3">{t("GENERAL.OR")}</div>
              </>
            )}
            {children}
            <p
              className={`text-center mt-3 account_signup ${!isGoogleSignBtn && "account_signupCreatePassword"}`}
            >
              {lowerSubmitText1}
              <span onClick={signInUpClick}> {lowerSubmitText2}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOnboardWrapper;
