import { useTranslation } from "react-i18next";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import "./ResumeScanning.scss";
const ResumeScanning = () => {
  const { t } = useTranslation();
  return (
    <ComingSoon
      title={t("GENERAL.RESUME_SCANNING")}
      isBackArrowVisible={true}
    />
  );
};

export default ResumeScanning;
