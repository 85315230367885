import React, { useEffect, useState } from "react";
import "./AccountScreen.scss";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import AccountDetails from "./AccountDetails"; // Screen 1
import TransactionHistory from "./TransactionHistory"; // Screen 2
import { useOnlineStatus } from "ui-components";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import {
  getCompanyAccountInfo,
  getCompanyTransactionHistory,
} from "../../service/Account/Account";
import useDebounce from "../../utils/Hooks/useDebounce";
import Header from "../../components/Assessment/Header/Header";

const AccountScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("account");
  const isOnline = useOnlineStatus();
  const [accountDetails, setAccountDetails] = useState<any>({});
  const [transaction, setTransactionHistory] = useState<any>([]);
  const [transactionsSearch, setTransactionSearch] = useState<any>("");
  const searchTransaction = useDebounce(transactionsSearch.trim(), 700);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isData, setIsData] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    handleComapnyAccountDetails();
    transactionHistory();
  }, [location?.state?.payment]);

  useEffect(() => {
    transactionHistory();
  }, [searchTransaction, currentPage, pageSize]);
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("transaction-history")) {
      setActiveTab("transaction-history");
    } else {
      setActiveTab("account");
      navigate("account-details", { replace: true });
    }
  }, [navigate, location.pathname]);

  const handleComapnyAccountDetails = async () => {
    const companyId = localStorage.getItem("companyId") as string;
    const branchId = localStorage.getItem("branchId") as string;
    const accountDetails = await getCompanyAccountInfo(companyId, branchId);
    setAccountDetails(accountDetails);
  };

  const transactionHistory = async () => {
    const companyId = localStorage.getItem("companyId") as string;
    const branchId = localStorage.getItem("branchId") as string;
    const transactionDetails = await getCompanyTransactionHistory(
      companyId,
      branchId,
      pageSize,
      currentPage,
      searchTransaction,
    );
    setTransactionHistory(transactionDetails?.data);
    if (transactionDetails?.totalCount) {
      setIsData(true);
    }
    setTotalCount(transactionDetails?.totalCount);
  };
  const handleTabClick = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
    if (tab === "account") {
      navigate("account-details", { replace: true });
    } else if (tab === "transaction-history") {
      navigate("transaction-history", { replace: true });
    }
  };
  return (
    <div className={`account-screen ${!isOnline && "mt-5"}`}>
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      <Header />
      <header className="header">
        <div className={`companyNameArrow ${!isOnline && "mt-5"}`}>
          <span>{accountDetails?.companyName}</span>
        </div>
      </header>
      <div className="contentSec">
        <div className="accountsSidebar col-md-2">
          <div
            className={`sidebar-item ${activeTab === "account" ? "active" : ""}`}
            onClick={() => handleTabClick("account")}
          >
            {t("GENERAL.ACCOUNT")}
          </div>
          <div
            className={`sidebar-item ${activeTab === "transaction-history" ? "active" : ""}`}
            onClick={() => handleTabClick("transaction-history")}
          >
            {t("GENERAL.TRANSACTION_HISTORY")}
          </div>
        </div>
        <div className="col-md-10 col-sm-10">
          <Routes>
            <Route
              path="/account-details"
              element={<AccountDetails accountDetails={accountDetails} />}
            />
            <Route
              path="/transaction-history"
              element={
                <TransactionHistory
                  hasTransactionData={isData}
                  setTransactionSearch={setTransactionSearch}
                  transactionsSearch={transactionsSearch}
                  transaction={transaction}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalCount={totalCount}
                />
              }
            />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AccountScreen;
