import { lazy, Suspense, useContext, useEffect } from "react";
import "./index.scss";
import { useState } from "react";
const AssessmentPreview = lazy(
  () =>
    import("../../components/Assessment/AssessmentPreview/AssessmentPreview"),
);

const TemplateLeftSide = lazy(
  () => import("./sections/AssessmentScope/TemplateLeftSide/TemplateLeftSide"),
);
import Head from "./sections/AssessmentScope/Head/Head";
import { setSessionStorageItem } from "../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../utils/SessionStorageMethod/getSessionStorageItem";
import { removeSessionStorageItem } from "../../utils/SessionStorageMethod/removeSessionStorageItem";
import { AssessmentJsonContext } from "../../context/JsonContext/JsonContext";
import ComponentIds from "../../utils/Json/componentIds.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAssessmentTemplate,
  // getPublicAndCompaniesPrivateAssessment,
  getRoleCentricRender,
} from "../../service/Scope/Scope";
import {
  createAssessmentDraftJson,
  getCreateAssessmentDraftJson,
} from "../../service/DraftJson/DraftJson";
import { checkKeysInSessionStorage } from "../../utils/Common/checkKeysInSessionStorage";
import SetDraftJsonInSession from "./AssessmentDraftJson/SetDraftJsonInSession";
import { assessmentDraftJson } from "../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import { Form, Formik } from "formik";
import {
  determinePageType,
  drafteditedformData,
  editedExistingformData,
  editedformData,
  initialScopeData,
  transformExistingTempalteData,
  addDisableCss,
  findValuesForKey,
  checkUniqueIdMatch,
  mergeSkills,
  getAssessmentPath,
} from "../../utils/Common/Constant";
import useDebounce from "../../utils/Hooks/useDebounce";
import { AssessmentButton } from "ui-components";
import { useTranslation } from "react-i18next";
import { getAssessmentDetailsByAssessementId } from "../../service/SubmitAssessment/SubmitAssessment";
import CustomModal from "../../components/CustomModal/CustomModal";
import CircleCrossIcon from "../../utils/Images/CircleCrossIcon";

const Assessment: React.FunctionComponent = () => {
  const { tempUniqueId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  // to identify wheather it is created assessment or edited assessment
  const editedParams = new URLSearchParams(location.search).get("edited");

  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const activeStep = determinePageType(pathname);
  const [existingTemplatePreview, setExistingTemplatePreview] = useState(
    getSessionStorageItem("existingPreview") || null,
  );
  const [existingData, setExistingData] = useState(
    getSessionStorageItem("existingData") || null,
  );
  const [existingTemplateSearch, setExistingTemplateSearch] = useState(""); // search existing tempalte
  const searchExistingTemplate = useDebounce(
    existingTemplateSearch.trim(),
    700,
  );
  // TemplateLeftSide state
  const [createTemplate, setCreateTemplate] = useState("EXISTING"); // "EXISTING" || "OWN"
  const [existingTemplate, setExistingTemplate] = useState<any>([]);
  const [templateCurrentPage, setTemplateCurrentPage] = useState(1);
  const [totalTemplate, setTotalTemplate] = useState(0);
  const renderRouteName = "template";
  const [isCompleteProfileModalOPen, setIsCompleteProfileModalOPen] =
    useState(false);
  const templatePageSize = 10;
  const navigate = useNavigate();
  const industriesSkillId = localStorage.getItem("industryId") ?? "";
  // const companyId = localStorage.getItem("companyId") ?? "";
  const userId = localStorage.getItem("companyId") ?? "";
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  const { state, dispatch } = useContext(AssessmentJsonContext) ?? {
    state: {
      renderJson: {},
      count: 0,
    },
    dispatch: () => {},
  };
  useEffect(() => {
    industriesSkillId == "null" && setIsCompleteProfileModalOPen(true);
  }, []);
  useEffect(() => {
    (async () => {
      const getJsonData = await getRoleCentricRender(renderRouteName);
      dispatch({
        type: "UPDATE_CENTRIC_RENDER_JSON",
        payload: getJsonData,
      });
    })();

    const isKeyPresent = checkKeysInSessionStorage(
      ["existingPreview", "existingData", "Type", "titleHeadContent"],
      "any",
    );
    if (
      (!isKeyPresent && !editedParams) ||
      checkUniqueIdMatch(tempUniqueId, tempUniqueIdBySession)
    ) {
      // api call to set store value in session
      SetDraftJsonInSession(userId, tempUniqueId, pathname);
    }
  }, []);

  // this useEffect render when we search template
  useEffect(() => {
    // industriesSkillId !== "null" &&
    (async () => {
      // const existingTemplate = await getPublicAndCompaniesPrivateAssessment(
      //   industriesSkillId,
      //   companyId,
      //   searchExistingTemplate,
      //   templateCurrentPage,
      //   templatePageSize,
      // );
      const existingTemplateNew = await getAssessmentTemplate(
        searchExistingTemplate,
        templateCurrentPage,
        templatePageSize,
      );

      const transformexistingTemplate = transformExistingTempalteData(
        existingTemplateNew?.data,
      );
      // const transformexistingTemplatenew = transformExistingTempalteData(
      //   existingTemplateNew?.data,
      // );
      const webTemplateId = location?.state?.templateID ?? false;
      if (webTemplateId || false) {
        // work when we redirect from websites template
        const updateTemplate = transformexistingTemplate?.filter(
          (item: any) => {
            return item?.assessmentId === location?.state?.templateID;
          },
        );
        const existing = mergeSkills(updateTemplate[0] ?? {});
        setExistingData(existing);
        setExistingTemplatePreview(existing);
        setSessionStorageItem("existingData", existing);
        setSessionStorageItem("existingPreview", existing);
      }
      setExistingTemplate(transformexistingTemplate);
      setTotalTemplate(existingTemplateNew?.totalCount);
    })();
  }, [searchExistingTemplate, templateCurrentPage]);

  // Run this effect whenever the session storage is updated

  const existingPreview = getSessionStorageItem("existingPreview");

  // Check if the items exist before accessing their properties
  const existingPreviewAssessmentTitle = existingPreview?.assessmentTitle || 1;

  useEffect(() => {
    setExistingTemplatePreview(getSessionStorageItem("existingPreview") || []);
    setExistingData(getSessionStorageItem("existingData") || []);
  }, [existingPreviewAssessmentTitle]);

  // render when edited assessment
  useEffect(() => {
    (async () => {
      if (editedParams) {
        const draftRes = await getCreateAssessmentDraftJson(
          userId,
          tempUniqueId,
        );
        if (
          Object.keys(draftRes?.data?.data).length > 0 &&
          (draftRes?.data?.data?.createAssessmentJson?.build?.assessmentSkill
            ?.length ||
            draftRes?.data?.data?.createAssessmentJson?.template
              ?.existingPreview?.length ||
            draftRes?.data?.data?.createAssessmentJson?.custom
              ?.AssessmentCustomQuestions)
        ) {
          drafteditedformData(draftRes?.data?.data);
        } else {
          const editedData = await getAssessmentDetailsByAssessementId(
            tempUniqueId ?? "",
          );
          setSessionStorageItem("isStoreEditedData", true);
          if (editedData?.data?.templateId) {
            const templateData = await getAssessmentDetailsByAssessementId(
              editedData?.data?.assessmentId ?? {},
            );
            const existingPreviewData = editedExistingformData(templateData);
            const existing = mergeSkills(existingPreviewData);
            setSessionStorageItem("existingPreview", existing);
            setSessionStorageItem("existingData", existingPreviewData);
            setExistingData(existingPreviewData);
            setExistingTemplatePreview(existingPreviewData);
            editedformData(editedData);
          } else {
            const skills = editedData?.data?.assessmentSkills?.map(
              (item: any) => {
                return { ...item, id: Math.random() };
              },
            );
            setSessionStorageItem("ownPreview", skills);
            editedformData(editedData);
          }
        }
      }
    })();
  }, []);

  // // to move to next page
  const handleNext = async (count: number) => {
    let nextStep;
    if (count != null && count != undefined && typeof count === "number") {
      nextStep = activeStep < 5 ? count : activeStep;
    } else {
      nextStep = activeStep < 5 ? activeStep + 1 : activeStep;
    }
    if (activeStep < count) {
      await createAssessmentDraftJson(assessmentDraftJson(tempUniqueId));
    }
    setSessionStorageItem("currentStep", nextStep);
    navigate(
      getAssessmentPath(nextStep, tempUniqueId, currentLanguage, editedParams),
    );
  };
  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      setSessionStorageItem("currentStep", count);
      navigate(
        getAssessmentPath(count, tempUniqueId, currentLanguage, editedParams),
      );
    }
  };

  const onGoPress = () => {
    setIsCompleteProfileModalOPen(false);
    navigate(`/${currentLanguage}/create-profile`, {
      state: { editProfile: false },
    });
  };
  return (
    <Suspense>
      <Formik
        initialValues={initialScopeData}
        onSubmit={() => {
          // handleNext();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <Suspense>
                <div className="skillSec">
                  <div className="row">
                    <div
                      className={`${addDisableCss(
                        findValuesForKey(
                          state?.renderJson,
                          "scope",
                          ComponentIds?.scope?.createTemplateKey,
                        ),
                      )} col-md-6`}
                    >
                      <TemplateLeftSide
                        existingTemplatePreview={existingTemplatePreview}
                        addbtnCallBack={(data) => {
                          removeSessionStorageItem("existingPreview");
                          removeSessionStorageItem("ownPreview");
                          removeSessionStorageItem("existingData");
                          const existing = mergeSkills(data ?? {});
                          setSessionStorageItem("existingPreview", existing);
                          setSessionStorageItem("existingData", data);
                          setExistingData(data);
                          setExistingTemplatePreview(data);
                        }}
                        setExistingTemplateSearch={setExistingTemplateSearch}
                        createTemplate={createTemplate}
                        existingTemplate={existingTemplate}
                        setCreateTemplate={setCreateTemplate}
                        templateCurrentPage={templateCurrentPage}
                        setTemplateCurrentPage={setTemplateCurrentPage}
                        totalTemplate={totalTemplate}
                        templatePageSize={templatePageSize}
                        existingTemplateSearch={existingTemplateSearch}
                      />
                    </div>
                    <div className="col-md-6">
                      {createTemplate === "EXISTING" && (
                        <AssessmentPreview
                          existingTemplatePreview={existingData}
                          removeCallBack={(data) => {
                            removeSessionStorageItem("existingPreview");
                            removeSessionStorageItem("existingData");
                            setExistingTemplatePreview(data);
                            setExistingData(data);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Suspense>
            </Form>
          );
        }}
      </Formik>
      {isCompleteProfileModalOPen && (
        <CustomModal
          show={isCompleteProfileModalOPen}
          isHeaderVisible={false}
          isFooterVisible={false}
          className="Complete-Profile-Modal"
        >
          <div
            onClick={() => setIsCompleteProfileModalOPen(false)}
            className="close-Profile-Modal"
          >
            <CircleCrossIcon />
          </div>
          <div>
            <p className="modalHeading">{t("GENERAL.COMPLETE_YOUR_PROFILE")}</p>
            <div className={"goBtnWrapper"}>
              <AssessmentButton className={"goBtn"} onClick={onGoPress}>
                <span>{t("GENERAL.GO")}</span>
              </AssessmentButton>
            </div>
          </div>
        </CustomModal>
      )}
    </Suspense>
  );
};

export default Assessment;
